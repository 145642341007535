import * as React from 'react';
import { ElementType, ReactNode } from 'react';
import classnames from 'classnames';
import { useStyles } from './styles';

interface TypographyProps {
  component: ElementType;
  children: ReactNode;
  className?: string;
}

export const Typography: React.FC<TypographyProps> = ({
  component: Component,
  children,
  className
}) => {
  const styles = useStyles({ component: Component });
  return (
    <Component className={classnames(styles.typography, className)}>
      {children}
    </Component>
  );
};
