import * as React from 'react';
import { useContext } from 'react';
import { Button } from '@/components';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import { Menu } from './Menu';

export const Home = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const {
    imageUrl,
    asset_list: { welcome_foreground_image }
  } = useContext(AssetsContext);

  return (
    <div className={styles.home}>
      <Menu />
      <div className={styles.logoWrap}>
        <img
          className={styles.logo}
          src={`${imageUrl}/${welcome_foreground_image}`}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          onClick={() => navigate('/createCustomer')}
          className={styles.button}
          text="Sign up"
          size="large"
        />
        <Button
          onClick={() => navigate('/search')}
          className={styles.button}
          text="Search customer"
          size="large"
        />
      </div>
    </div>
  );
};
