import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  customer: {
    '@media (max-width: 576px)': {
      padding: '0 30px'
    }
  },
  leftBlock: {
    '@media (max-width: 576px)': {
      display: 'none'
    }
  },
  qrCodeBlock: {
    paddingLeft: 20,
    marginBottom: 70,
    '& canvas': {
      width: '320px!important',
      height: '320px !important'
    }
  },
  qrCodeBlockMobile: {
    marginBottom: 70,
    display: 'none',
    '& canvas': {
      width: '100%!important',
      height: '100% !important'
    },
    '@media (max-width: 576px)': {
      display: 'block'
    }
  },
  backButton: {
    maxWidth: 250
  },
  backButtonMobile: {
    display: 'none',
    '@media (max-width: 576px)': {
      display: 'block'
    }
  },
  br: {
    display: 'none',
    '@media (max-width: 576px)': {
      display: 'block'
    }
  },
  title: {
    marginBottom: 30,
    '@media (max-width: 576px)': {
      textAlign: 'center',
      width: '100%'
    }
  },
  subtitle: {
    paddingBottom: 10
  },
  personalInfo: {
    paddingBottom: 20
  },
  actionBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20
  },
  button: {
    maxWidth: 200,
    marginBottom: 15,
    '@media (max-width: 576px)': {
      maxWidth: '100%'
    }
  },
  scanBlockTitle: {
    marginBottom: 20
  },
  scan: {
    borderRadius: 15,
    border: `1px solid ${theme.colors.black}`,
    padding: '10px 10px',
    '@media (max-width: 576px)': {
      marginBottom: 20
    }
  },
  scanDetails: {
    padding: '5px 0'
  }
}));
