import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { ContentBlock, Loader, Typography } from '@/components';
import {
  CreateUpdateCustomerForm,
  UserCreateData
} from './CreateUpdateCustomerForm';
import { useStyles } from './styles';
import {
  addUserAgreements,
  createCustomer,
  createShare,
  getAgreements,
  getCustomerProvision,
  getUser,
  patchUser,
  provisionCustomer
} from '@/api';
import { AppContext, AlertContext } from '@/GlobalProvider/GlobalProvider';
import { useNavigate, useParams } from 'react-router-dom';

export const CreateCustomer = () => {
  const styles = useStyles();
  const { id } = useContext(AppContext);
  const { setAlert } = useContext(AlertContext);
  const navigation = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  const createNewUser = async (data: UserCreateData) => {
    try {
      if (data.email) {
        const newCustomerProvision = await getCustomerProvision(id, data.email);

        if (newCustomerProvision.id) {
          setAlert({
            text: 'A user with this email address already exists.',
            variation: 'error'
          });
          return;
        }
      }

      if (data.phone) {
        const newCustomerProvision = await getCustomerProvision(id, data.phone);

        if (newCustomerProvision.id) {
          setAlert({
            text: 'A user with this phone number already exists.',
            variation: 'error'
          });
          return;
        }
      }

      const response = await createCustomer(data, id);
      await provisionCustomer(response.id, id, data.email || data.phone);
      await createShare(id, response.id);
      const agreementsResponse = await getAgreements(id);
      await addUserAgreements(
        response.id,
        agreementsResponse,
        `${response.firstName} ${response.lastname}`
      );

      navigation(`/customers/${response.id}`);
    } catch (e) {
      const error = JSON.stringify(e.message);

      if (error === '"Network Error"') {
        setAlert({
          text: 'Check your internet and try again.',
          variation: 'error'
        });
      } else {
        setAlert({
          text: 'User with this data was not found!',
          variation: 'error'
        });
      }
    }
  };

  const updateUser = async (value: UserCreateData) => {
    try {
      if (currentCustomer) {
        await patchUser({ ...value, id: currentCustomer.id });
        navigation(`/customers/${currentCustomer.id}`);
      }
    } catch (e) {
      const error = JSON.stringify(e.message);

      if (error === '"Network Error"') {
        setAlert({
          text: 'Check your internet and try again.',
          variation: 'error'
        });
      } else {
        setAlert({
          text: 'User with this data was not found!',
          variation: 'error'
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (params.customerId) {
        const customerData = await getUser(params.customerId);
        if (!customerData.status) {
          setCurrentCustomer(customerData);
        }
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <ContentBlock
      hasBackButton
      backHandler={
        !!currentCustomer &&
        (() => navigation(`/customers/${params.customerId}`))
      }
    >
      <div className={styles.header}>
        <Typography component="h3">Hello!</Typography>
        <Typography component="h2">
          {!!currentCustomer ? 'Update customer' : 'Sign up to get started '}
        </Typography>
      </div>
      <CreateUpdateCustomerForm
        submitHandler={currentCustomer ? updateUser : createNewUser}
        defaultValue={currentCustomer}
        isUpdateForm={!!currentCustomer}
      />
    </ContentBlock>
  );
};
