import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { createBrowserHistory } from 'history';

import './global.scss';

import * as config from './config.json';
import { Authenticator } from '@/authentication';

Amplify.configure(config);

// made updates to support browser refresh with react routing on aws-static webpage hosting
// details here: https://via.studio/journal/hosting-a-reactjs-app-with-routing-on-aws-s3
const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
if (path) {
  history.replace(path);
}

ReactDOM.render(
  <React.StrictMode>
    <Authenticator />
  </React.StrictMode>,
  document.getElementById('root')
);
