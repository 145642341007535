import { Formik } from 'formik';
import * as React from 'react';
import 'react-phone-input-2/lib/style.css';
import { FC, useEffect, useState, useContext } from 'react';
import * as Yup from 'yup';
import { TextInput, Button, Typography, PhoneInputField } from '@/components';
import {
  CheckboxField,
  Flex,
  Grid,
  Radio,
  RadioGroupField,
  View
} from '@aws-amplify/ui-react';
import { useStyles } from './styles';
import { EMAIL_REGEX } from '@/regex';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import { convertAgeToDate, convertDateToAge } from '@/utils';
import { useNavigate, useParams } from 'react-router-dom';

export interface User {
  id?: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  dateOfBirth: number;
  gender: string;
  height: number;
  weight: number;
}

export interface UserCreateData extends User {
  heightMeasure: 'cm' | 'fnIn';
  weightMeasure: 'kgs' | 'lbs';
  termsAndConditions?: boolean;
}

const getInitialValues = (gender = 'male'): UserCreateData => ({
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  gender,
  height: null,
  heightMeasure: 'fnIn',
  weight: null,
  weightMeasure: 'lbs',
  termsAndConditions: false
});

const getSchema = (phoneLength) =>
  Yup.object().shape(
    {
      email: Yup.string()
        .when('phone', {
          is: (phone) => !phone,
          then: Yup.string().required(
            'Phone number or email address is required'
          ),
          otherwise: Yup.string()
        })
        .matches(EMAIL_REGEX, 'Enter valid email address'),
      phone: Yup.string().when('email', {
        is: (email) => !email,
        then: Yup.string()
          .required('Phone number or email address is required')
          .test(
            'len',
            `Must be exactly ${phoneLength} characters`,
            (val) => val?.length === phoneLength
          ),
        otherwise: Yup.string().test(
          'len',
          `Must be exactly ${phoneLength} characters`,
          (val) => {
            if (!val || val === '1') return true;
            return val?.length === phoneLength;
          }
        )
      }),
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      dateOfBirth: Yup.number()
        .nullable()
        .required('Age is required')
        .min(18, 'You must enter an age 18 or older')
        .max(120, 'Max age 120 years'),
      feet: Yup.number().when('heightMeasure', {
        is: (heightMeasure) => heightMeasure !== 'cm',
        then: Yup.number()
          .required('Feet is required')
          .min(5, 'Min feet value is 5')
          .max(7, 'Max feet value is 7')
      }),
      inch: Yup.number().when('heightMeasure', {
        is: (heightMeasure) => heightMeasure !== 'cm',
        then: Yup.number()
          .required('Inch is required')
          .min(0, 'Min inch value is 0')
          .max(11, 'Max inch value is 11')
      }),
      height: Yup.number().when('heightMeasure', {
        is: (heightMeasure) => heightMeasure === 'cm',
        then: Yup.number()
          .nullable()
          .required('Height is a required')
          .min(100, 'Height must be greater than or equal to 100 см')
          .max(250, 'Height must be less than or equal to 250 см')
      }),
      weight: Yup.number()
        .nullable()
        .required('Weight is a required')
        .when('weightMeasure', {
          is: (weightMeasure) => weightMeasure === 'kgs',
          then: Yup.number()
            .nullable()
            .min(30, 'Weight must be greater than or equal to 30 kgs')
            .max(200, 'Weight must be less than or equal to 200 kgs'),
          otherwise: Yup.number()
            .nullable()
            .min(66, 'Weight must be greater than or equal to 66 lbs')
            .max(440, 'Weight must be less than or equal to 440 lbs')
        }),
      termsAndConditions: Yup.bool().oneOf([true])
    },
    [
      ['phone', 'email'],
      ['email', 'phone'],
      ['height', 'heightMeasure'],
      ['weight', 'weightMeasure']
    ]
  );

interface CreateUpdateCustomerForm {
  submitHandler: (data: UserCreateData) => void;
  isUpdateForm?: boolean;
  defaultValue?: User;
  isQuickUpdate?: boolean;
}

const oneCmInFt = 0.032808399;
const onePnInKg = 2.2046226218;
const oneCmInInch = 0.393701;
const inchesInFt = 12;

const covertCmToFt = (value: number): [number, number] => {
  if (value < 153) {
    return [5, 0];
  } else if (value > 241) {
    return [7, 11];
  }
  const ftValue = Math.floor(value * oneCmInFt);
  const incheValue = Math.floor((value - ftValue / oneCmInFt) * oneCmInInch);
  return [ftValue, incheValue];
};

const convetFtToCm = (feet: number, inch: number): number => {
  return Math.round(feet / oneCmInFt + inch / oneCmInInch);
};

const converFtToInches = (feet: number, inch: number): number => {
  return feet * inchesInFt + inch;
};

export const converInchesToFt = (value: number): [number, number] => {
  return [~~(value / inchesInFt), value % 12];
};

export const CreateUpdateCustomerForm: FC<CreateUpdateCustomerForm> = ({
  submitHandler,
  isUpdateForm,
  defaultValue
}) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const {
    asset_list: { terms_and_conditions_url, privacy_policy_url },
    behavior_list: { gender_selection_flag, customer_account_handle }
  } = useContext(AssetsContext);

  const onlyPhone = customer_account_handle === 'phone_only';
  const onlyEmail = customer_account_handle === 'email_only';
  const showPhoneInput =
    onlyPhone || customer_account_handle === 'email_or_phone';
  const showEmailInput =
    onlyEmail || customer_account_handle === 'email_or_phone';

  const [wasFirstRender, setWasFirstRender] = useState(false);
  const [phoneLength, setPhoneLength] = useState(11);
  const ftInputRef = React.useRef<HTMLInputElement>();
  const inInputRef = React.useRef<HTMLInputElement>();
  const cmInputRef = React.useRef<HTMLInputElement>();
  const weightInputRef = React.useRef<HTMLInputElement>();
  const ageInputRef = React.useRef<HTMLInputElement>();
  const emailInputRef = React.useRef<HTMLInputElement>();

  const defaultGender =
    gender_selection_flag === 'assumed_male'
      ? 'male'
      : gender_selection_flag === 'assumed_female'
      ? 'female'
      : null;

  const onSubmit = (data) => {
    let { feet, inch, weight } = data;
    if (data.heightMeasure === 'cm') {
      [feet, inch] = covertCmToFt(data.height);
    }

    if (data.weightMeasure === 'kgs') {
      weight = weight * onePnInKg;
    }

    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (typeof data[key] === 'string') {
        data[key] = data[key].replaceAll(/\s+/g, '');
      }
    });

    submitHandler({
      ...data,
      dateOfBirth: convertAgeToDate(data.dateOfBirth),
      email: data.email.toLowerCase(),
      height: converFtToInches(feet, inch),
      weight: Math.round(weight)
    });
  };

  const termsAndConditionsLabel = (
    <div>
      I accept{' '}
      <a target="_blank" href={terms_and_conditions_url} rel="noreferrer">
        Terms of Use
      </a>{' '}
      and{' '}
      <a target="_blank" href={privacy_policy_url} rel="noreferrer">
        Privacy Policy
      </a>
      .
    </div>
  );

  const getFeetAndInch = (height) => {
    const [feet, inch] = converInchesToFt(height);
    return { feet, inch };
  };

  const initialValues = getInitialValues(defaultGender || 'male');

  return (
    <Formik
      initialValues={
        defaultValue
          ? {
              ...defaultValue,
              dateOfBirth: convertDateToAge(defaultValue.dateOfBirth),
              ...getFeetAndInch(defaultValue.height),
              heightMeasure: 'fnIn',
              weightMeasure: 'lbs'
            }
          : { ...initialValues, ...getFeetAndInch(initialValues.height) }
      }
      validationSchema={getSchema(phoneLength)}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        validateForm
      }) => {
        useEffect(() => {
          validateForm();
        }, [phoneLength]);

        useEffect(() => {
          setFieldValue('height', convetFtToCm(values.feet, values.inch));
        }, [values.feet, values.inch]);

        useEffect(() => {
          if (wasFirstRender) {
            if (values.heightMeasure === 'fnIn') {
              const [feet, inch] = covertCmToFt(values.height);
              setFieldValue('feet', feet);
              setFieldValue('inch', inch);

              ftInputRef.current.inputMode = 'numeric';
              inInputRef.current.inputMode = 'numeric';
            } else {
              cmInputRef.current.inputMode = 'numeric';
              setFieldValue('height', convetFtToCm(values.feet, values.inch));
            }
          }
        }, [values.heightMeasure]);

        useEffect(() => {
          if (wasFirstRender) {
            if (values.weightMeasure === 'lbs') {
              setFieldValue('weight', Math.round(values.weight * onePnInKg));
            } else {
              setFieldValue('weight', Math.round(values.weight / onePnInKg));
            }
          }
        }, [values.weightMeasure]);

        useEffect(() => {
          ftInputRef.current.inputMode = 'numeric';
          inInputRef.current.inputMode = 'numeric';
          weightInputRef.current.inputMode = 'numeric';
          ageInputRef.current.inputMode = 'numeric';
          if (emailInputRef.current) {
            emailInputRef.current.inputMode = 'email';
          }
          setWasFirstRender(true);
        }, []);

        return (
          <Grid
            className={styles.formContainer}
            columnGap="3rem"
            rowGap="0.5rem"
            templateColumns="1fr 1fr 1fr 1fr 1fr 1fr"
          >
            <View columnSpan={[6, 6, 3]}>
              <TextInput
                label="First Name"
                type="text"
                name="firstName"
                placeholder="Enter first name"
                value={values.firstName}
                onBlur={handleBlur}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/\s/g, '');
                  handleChange(e);
                }}
                hasError={touched.firstName && !!errors.firstName}
                errorMessage={errors.firstName as string}
              />
            </View>
            <View columnSpan={[6, 6, 3]}>
              <TextInput
                label="Last Name"
                name="lastName"
                placeholder="Enter last name"
                value={values.lastName}
                onBlur={handleBlur}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/\s/g, '');
                  handleChange(e);
                }}
                hasError={touched.lastName && !!errors.lastName}
                errorMessage={errors.lastName as string}
              />
            </View>
            {showPhoneInput && (
              <View columnSpan={[6, 6, onlyPhone ? 6 : 3]}>
                <PhoneInputField
                  value={values.phone}
                  error={errors.phone as string}
                  touched={touched.phone as boolean}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  disabled={!!isUpdateForm}
                  setPhoneLength={setPhoneLength}
                />
              </View>
            )}
            {showEmailInput && (
              <View columnSpan={[6, 6, onlyEmail ? 6 : 3]}>
                <TextInput
                  ref={emailInputRef}
                  label="Email Address"
                  type="text"
                  name="email"
                  placeholder="Enter email address"
                  value={values.email.toLowerCase()}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/\s/g, '');
                    handleChange(e);
                  }}
                  hasError={touched.email && !!errors.email}
                  errorMessage={errors.email as string}
                  disabled={!!isUpdateForm}
                />
              </View>
            )}
            <View columnSpan={6}>
              <Typography component="h6">
                Phone number or email address are mandatory for sign up.
              </Typography>
            </View>
            <View columnSpan={[6, 6, 3]}>
              <TextInput
                ref={ageInputRef}
                label="Age"
                placeholder="Age"
                type="number"
                name="dateOfBirth"
                value={values.dateOfBirth}
                className={styles.ageInput}
                onBlur={handleBlur}
                onChange={handleChange}
                hasError={touched.dateOfBirth && !!errors.dateOfBirth}
                errorMessage={errors.dateOfBirth as string}
              />
            </View>
            {!defaultGender && (
              <View columnSpan={[6, 6, 3]}>
                <RadioGroupField label="Gender" name="gender">
                  <Flex>
                    <Radio
                      checked={values.gender === 'male'}
                      name="gender"
                      value="male"
                      onChange={handleChange}
                    >
                      Male
                    </Radio>
                    <Radio
                      checked={values.gender === 'female'}
                      name="gender"
                      value="female"
                      onChange={handleChange}
                    >
                      Female
                    </Radio>
                  </Flex>
                </RadioGroupField>
              </View>
            )}
            <View columnSpan={[6, 6, 3]} className="age-input">
              <Flex>
                <TextInput
                  ref={weightInputRef}
                  label="Weight"
                  type="number"
                  placeholder="Weight"
                  className={styles.input}
                  value={values.weight}
                  name="weight"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  hasError={touched.weight && !!errors.weight}
                  errorMessage={errors.weight as string}
                />
                <RadioGroupField
                  label=""
                  name="weightMeasure"
                  direction="row"
                  className={styles.radioButtons}
                >
                  <Radio
                    checked={values.weightMeasure === 'lbs'}
                    name="weightMeasure"
                    value="lbs"
                    onChange={handleChange}
                  >
                    LB
                  </Radio>
                  <Radio
                    checked={values.weightMeasure === 'kgs'}
                    name="weightMeasure"
                    value="kgs"
                    onChange={handleChange}
                  >
                    KG
                  </Radio>
                </RadioGroupField>
              </Flex>
            </View>
            <View columnSpan={[6, 6, 3]}>
              <Flex>
                {values.heightMeasure === 'cm' ? (
                  <TextInput
                    ref={cmInputRef}
                    label="Height"
                    type="number"
                    placeholder="Height"
                    className={styles.input}
                    name="height"
                    value={values.height}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    hasError={touched.height && !!errors.height}
                    errorMessage={errors.height as string}
                  />
                ) : (
                  <Flex>
                    <TextInput
                      ref={ftInputRef}
                      label="Height"
                      type="number"
                      placeholder="Feet"
                      name="feet"
                      value={values.feet}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      hasError={touched.feet && !!errors.feet}
                      errorMessage={errors.feet as string}
                      className={styles.heightSmallInput}
                    />
                    <TextInput
                      ref={inInputRef}
                      label=""
                      type="number"
                      placeholder="Inch"
                      name="inch"
                      value={values.inch}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      hasError={touched.inch && !!errors.inch}
                      errorMessage={errors.inch as string}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      className={[
                        styles.heightSmallInput,
                        styles.heightSmallInputWithoutLabel
                      ]}
                    />
                  </Flex>
                )}
                <RadioGroupField
                  label=""
                  name="weightMeasure"
                  direction="row"
                  className={styles.radioButtons}
                >
                  <Radio
                    checked={values.heightMeasure === 'fnIn'}
                    name="heightMeasure"
                    value="fnIn"
                    onChange={handleChange}
                  >
                    FT/IN
                  </Radio>
                  <Radio
                    checked={values.heightMeasure === 'cm'}
                    name="heightMeasure"
                    value="cm"
                    onChange={handleChange}
                  >
                    CM
                  </Radio>
                </RadioGroupField>
              </Flex>
            </View>
            {!isUpdateForm && (
              <View columnSpan="6" className={styles.termsAndConditions}>
                <CheckboxField
                  value={''}
                  label={termsAndConditionsLabel}
                  name="termsAndConditions"
                  hasError={
                    touched.termsAndConditions && !!errors.termsAndConditions
                  }
                  onChange={handleChange}
                  required
                  errorMessage="You must agree before submitting."
                />
              </View>
            )}
            <View columnSpan="6" className={styles.buttonContainer}>
              {isUpdateForm && (
                <Button
                  buttonType="dark"
                  text="Back to user page"
                  className={styles.backButton}
                  onClick={() => navigate(`/customers/${params.customerId}`)}
                />
              )}
              <Button
                buttonType="secondary"
                text={isUpdateForm ? 'Update' : 'Sign up'}
                className={styles.submitButton}
                onClick={() => handleSubmit()}
              />
            </View>
          </Grid>
        );
      }}
    </Formik>
  );
};
