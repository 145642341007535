import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  wrapper: {
    width: '100%',
    '@media (max-width: 576px)': {
      paddingTop: 60
    }
  },
  logo: {
    position: 'absolute',
    top: 10,
    right: 0,
    width: 300,
    height: 70,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    '@media (max-width: 576px)': {
      width: 250
    }
  },
  contentBlock: {
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    maxWidth: 850,
    backgroundColor: theme.colors.white,
    borderRadius: 40,
    padding: '20px 30px',
    '@media (max-width: 576px)': {
      maxWidth: 370,
      borderRadius: 15,
      padding: '30px 25px'
    }
  },
  content: {
    position: 'relative',
    '@media (max-width: 576px)': {
      maxHeight: 500,
      overflow: 'auto'
    }
  },
  backButton: {
    position: 'absolute',
    top: 20,
    right: 40,
    cursor: 'pointer',
    width: 20,
    height: 20,
    zIndex: 100,
    '& span, &:before': {
      position: 'absolute',
      top: 9,
      height: 2,
      width: '100%',
      display: 'inline-block',
      background: theme.colors.black
    },
    '&:before': {
      content: '""',
      transform: 'rotate(45deg)'
    },
    '& span': {
      transform: 'rotate(-45deg)'
    }
  }
}));
