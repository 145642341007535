import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import * as React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';

interface ContentBlockProps {
  children: React.ReactNode;
  hasBackButton?: boolean;
  backHandler?: () => void;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  children,
  hasBackButton,
  backHandler
}) => {
  const assets = useContext(AssetsContext);

  const { imageUrl, asset_list } = assets || {};
  const navigate = useNavigate();

  const styles = useStyles();

  const onBackButtonPress = backHandler || (() => navigate('/'));

  return (
    <div className={styles.wrapper}>
      {imageUrl && asset_list?.company_logo_image && (
        <div className={styles.logo}>
          <img src={`${imageUrl}/${asset_list?.company_logo_image}`} />
        </div>
      )}
      <div className={styles.contentBlock}>
        {hasBackButton && (
          <div className={styles.backButton} onClick={onBackButtonPress}>
            <span />
          </div>
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
