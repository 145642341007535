import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  alertWrapper: {
    position: 'absolute',
    top: 20,
    padding: '0 20px',
    width: '100vw'
  }
});
