import * as React from 'react';
import { useState, useEffect } from 'react';
import { getApps, getAssetsFile, getDefaultAssetsFile } from '@/api';
import { ThemeProvider } from 'react-jss';
import { theme } from '@/theme';
import { Alert } from '@/components';
import { AlertProps } from '@aws-amplify/ui-react';

export const AppContext = React.createContext(null);
export const AssetsContext = React.createContext(null);
export const AlertContext = React.createContext(null);

const defaultAssetsUrl =
  'https://ms1-branding-assets.s3.amazonaws.com/AUI+Assets/V2/default';
const assetsUrl = 'https://ms1-branding-assets.s3.amazonaws.com/AUI+Assets/V2';

export const GlobalProvider = ({ children }) => {
  const [appData, setAppData] = useState(null);
  const [assetsDate, setAssetsDate] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [alert, setAlert] = useState<
    (AlertProps & { text: React.ReactElement }) | null
  >(null);

  useEffect(() => {
    const getAppData = async () => {
      const response = await getApps();
      const res = await getAssetsFile(response[0].id);
      if (res) {
        setImageUrl(`${assetsUrl}/${response[0].id}`);
        setAssetsDate(res);
      } else {
        const defaultRes = await getDefaultAssetsFile();
        setImageUrl(defaultAssetsUrl);
        setAssetsDate(defaultRes);
      }
      setAppData(response);
    };
    getAppData();
  }, []);

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(null);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [alert]);

  if (!appData) return null;

  return (
    <AppContext.Provider value={{ ...appData[0] }}>
      <AssetsContext.Provider value={{ ...assetsDate, imageUrl }}>
        <AlertContext.Provider value={{ setAlert }}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
          {alert && (
            <Alert {...alert} onDismiss={() => setAlert(null)}>
              {alert.text}
            </Alert>
          )}
        </AlertContext.Provider>
      </AssetsContext.Provider>
    </AppContext.Provider>
  );
};
