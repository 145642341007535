import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import * as React from 'react';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useStyles } from './styles';

export const Wrapper = () => {
  const assets = useContext(AssetsContext);

  const { imageUrl, asset_list } = assets || {};

  const hasAssets = imageUrl && asset_list?.background_image;

  const styles = useStyles({
    backgroundUrl: hasAssets
      ? `${imageUrl}/${asset_list?.background_image}`
      : null
  });

  return (
    <div className={styles.wrapper}>
      <Outlet />
    </div>
  );
};
