import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: ({ backgroundUrl }: { backgroundUrl: string }) => ({
    height: '100vh',
    width: '100vw',
    ...(backgroundUrl
      ? { backgroundImage: `url(${backgroundUrl})` }
      : { backgroundColor: 'black' }),
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
});
