import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  formInputs: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    '@media (max-width: 576px)': {
      flexDirection: 'column'
    }
  },
  input: {
    padding: '0 30px',
    width: 370,
    '@media (max-width: 576px)': {
      padding: '0 15px',
      width: 320,
      marginBottom: 10
    }
  },
  buttonBlock: {
    paddingTop: 60,
    maxWidth: 200,
    margin: '0 auto',
    '@media (max-width: 576px)': {
      paddingTop: 10
    }
  }
});
