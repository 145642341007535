import * as React from 'react';
import { useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  Home,
  CreateCustomer,
  SearchCustomer,
  Customer,
  Scans,
  Scan,
  Error
} from '@/pages';
import { Wrapper } from '@/components';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';

export const Router = () => {
  const { asset_file_version } = useContext(AssetsContext);

  const isSecondVersion = asset_file_version === '2';

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Wrapper />}>
          {isSecondVersion ? (
            <>
              <Route index element={<Home />} />
              <Route path="/createCustomer" element={<CreateCustomer />} />
              <Route
                path="/createCustomer/:customerId"
                element={<CreateCustomer />}
              />
              <Route path="/search" element={<SearchCustomer />} />
              <Route path="/customers/:customerId" element={<Customer />} />
              <Route path="/customers/scans/:customerId" element={<Scans />} />
              <Route path="/scans/:scanId/:customerId" element={<Scan />} />
            </>
          ) : (
            <Route index element={<Error />} />
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
