import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  home: {
    '@media (max-width: 576px)': {
      paddingTop: 150
    }
  },
  title: {
    marginBottom: 10
  },
  logoWrap: {
    margin: '0 auto 40px',
    maxWidth: 480,
    '@media (max-width: 576px)': {
      maxWidth: 300,
      margin: '0 auto 150px'
    }
  },
  logo: {
    width: '100%',
    objectFit: 'contain'
  },
  buttons: {
    maxWidth: 340,
    margin: '0 auto'
  },
  button: {
    '&:not(:last-child)': {
      marginBottom: 20
    }
  }
});
