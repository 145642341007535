import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 50,
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      marginBottom: 30
    }
  },
  title: {
    '@media (max-width: 576px)': {
      marginBottom: 20
    }
  },
  scans: {
    padding: '1rem',
    border: `1px solid ${theme.colors.lightGrey}`,
    borderRadius: 10,
    maxHeight: 400,
    overflow: 'auto',
    marginBottom: 30,
    '@media (max-width: 576px)': {
      maxHeight: 315
    }
  },
  scan: {
    border: `1px solid ${theme.colors.lightGrey}`,
    borderRadius: 10,
    padding: 15
  },
  scanInfo: {
    padding: '3px 0'
  },
  scanButton: {
    marginTop: 30,
    maxWidth: 250
  },
  backButton: {
    maxWidth: 200,
    '@media (max-width: 576px)': {
      maxWidth: '100%'
    }
  }
}));
