import { ContentBlock, Typography, Button } from '@/components';
import { AppContext } from '@/GlobalProvider/GlobalProvider';
import * as React from 'react';
import { useContext } from 'react';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  link: {
    color: 'blue'
  },
  retryButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  retryButton: {
    marginTop: 20,
    maxWidth: 200
  }
});

export const Error = () => {
  const { id } = useContext(AppContext);
  const styles = useStyles();

  const retryHandler = () => {
    window.location.reload();
  };

  return (
    <ContentBlock>
      <Typography component="h3">
        Your company information is not correctly configured. Please contact
        Size Stream Support{' '}
        <a href="mailto:support@sizestream.com" className={styles.link}>
          support@sizestream.com
        </a>{' '}
        with your company identifier {id}.
      </Typography>
      <div className={styles.retryButtonWrap}>
        <Button
          buttonType="dark"
          text="Retry"
          onClick={retryHandler}
          className={styles.retryButton}
        />
      </div>
    </ContentBlock>
  );
};
