import { ElementType } from 'react';
import { createUseStyles } from 'react-jss';

interface TypographyStylesProps {
  component: ElementType;
}

export const useStyles = createUseStyles((theme) => {
  const { colors } = theme;
  return {
    typography: ({ component }: TypographyStylesProps) => {
      switch (component) {
        case 'h1':
          return {
            color: colors.white,
            margin: 0,
            fontSize: 55,
            fontWeight: 500,
            textTransform: 'uppercase',
            textAlign: 'center'
          };
        case 'h2':
          return {
            color: colors.black,
            margin: 0,
            fontSize: 20,
            fontWeight: 400,
            letterSpacing: 1.1,
            position: 'relative',
            display: 'inline-block',
            '&:before': {
              content: '""',
              position: 'absolute',
              bottom: -2,
              left: 0,
              width: '100%',
              height: 3,
              display: 'inline-block',
              background: colors.black
            }
          };
        case 'h3':
          return {
            color: colors.black,
            margin: 0,
            fontSize: 20,
            fontWeight: 400
          };
        case 'h6':
          return {
            fontStyle: 'italic',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            color: colors.grey,
            margin: 0,
            fontSize: 18,
            fontWeight: 400
          };
        case 'p':
          return {
            color: colors.black,
            fontWeight: 300,
            fontSize: 18,
            margin: 0,
            '& span': {
              fontWeight: 500
            }
          };
      }
    }
  };
});
