import * as React from 'react';
import { getUser, getUserScans } from '@/api';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ContentBlock, Loader, Typography } from '@/components';
import { Grid, View } from '@aws-amplify/ui-react';
import * as QRCode from 'qrcode.react';
import { useStyles } from './styles';
import { AlertContext } from '@/GlobalProvider/GlobalProvider';
import { formatDateWithTime, formatPhoneNumber } from '@/utils';

export const Customer = () => {
  const { setAlert } = useContext(AlertContext);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [previousScan, setPreviousScan] = useState(null);
  const styles = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const customerData = await getUser(params.customerId);
      if (customerData.status) {
        if (customerData.status === 403) {
          setAlert({
            text: 'This account is not sharing to your company.',
            variation: 'error'
          });
        } else if (customerData.status === 404) {
          setAlert({
            text: 'This account was not found.',
            variation: 'error'
          });
        }
      } else {
        const scansData = await getUserScans(params.customerId);
        if (scansData.length > 0) {
          setPreviousScan(scansData[0]);
        }
        setCurrentCustomer(customerData);
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!currentCustomer) return null;

  return (
    <ContentBlock>
      <Grid
        templateColumns="1fr 1fr"
        columnGap="3rem"
        className={styles.customer}
      >
        <View columnSpan="1" className={styles.leftBlock}>
          <div className={styles.qrCodeBlock}>
            <QRCode id="qrCode" value={window.btoa(currentCustomer.id)} />
          </div>
          <div className={styles.backButton}>
            <Button
              buttonType="secondary"
              text="Back to Dashboard"
              onClick={() => navigate('/')}
            />
          </div>
        </View>
        <View columnSpan={[2, 2, 1]}>
          <Typography component="h2" className={styles.title}>
            Welcome, <br className={styles.br} /> {currentCustomer.firstName}{' '}
            {currentCustomer.lastName}
          </Typography>
          <div className={styles.qrCodeBlockMobile}>
            <QRCode id="qrCode" value={window.btoa(currentCustomer.id)} />
          </div>
          <div className={styles.personalInfo}>
            <Typography component="p" className={styles.subtitle}>
              <span>Phone:</span>{' '}
              {currentCustomer.phone
                ? formatPhoneNumber(currentCustomer.phone)
                : '-'}
            </Typography>
            <Typography component="p" className={styles.subtitle}>
              <span>Email:</span> {currentCustomer.email || '-'}
            </Typography>
          </div>
          <div className={styles.actionBlock}>
            <Button
              text="Update Profile"
              buttonType="dark"
              className={styles.button}
              onClick={() => navigate(`/createCustomer/${params.customerId}`)}
            />
            <Button
              text="Scan History"
              buttonType="dark"
              className={styles.button}
              onClick={() => navigate(`/customers/scans/${params.customerId}`)}
            />
          </div>
          {previousScan && (
            <div>
              <Typography component="p" className={styles.scanBlockTitle}>
                <span>Previous Scan Details:</span>
              </Typography>
              <div className={styles.scan}>
                <Typography component="p" className={styles.scanDetails}>
                  <span>Date:</span> {formatDateWithTime(previousScan.date)}
                </Typography>
                <Typography component="p" className={styles.scanDetails}>
                  <span>Status:</span>{' '}
                  {previousScan.status === 'complete'
                    ? 'Complete'
                    : previousScan.status === 'failed'
                    ? 'Failed'
                    : previousScan.status === 'waiting for data'
                    ? 'Waiting for Data'
                    : 'Processing'}
                </Typography>
              </div>
            </div>
          )}
          <div className={styles.backButtonMobile}>
            <Button
              buttonType="secondary"
              text="Back to Dashboard"
              onClick={() => navigate('/')}
            />
          </div>
        </View>
      </Grid>
    </ContentBlock>
  );
};
