import * as React from 'react';
import { FC, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { createUseStyles } from 'react-jss';

interface PhoneInputField {
  value: string;
  handleBlur: (e: any) => void;
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean
  ) => void;
  touched?: boolean;
  error?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  setPhoneLength?: (value: number) => void;
}

export const PhoneInputField: FC<PhoneInputField> = ({
  value,
  handleBlur,
  touched,
  error,
  setFieldValue,
  handleChange,
  disabled,
  setPhoneLength
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onValueChange = (phoneNumber, numberData) => {
    setPhoneLength(numberData.format.match(/\./g).length);
    setFieldValue('phone', phoneNumber);

    if (handleChange !== null) {
      handleChange(phoneNumber);
    }
  };

  const onBlurHandler = (e) => {
    handleBlur(e);
  };

  return (
    <div className="amplify-flex amplify-field amplify-textfield">
      <label className="amplify-label">Phone Number</label>
      <PhoneInput
        ref={inputRef}
        placeholder="Enter phone number"
        inputProps={{
          name: 'phone'
        }}
        value={value}
        onChange={onValueChange}
        country="us"
        onBlur={onBlurHandler}
        isInvalid={touched && error}
        disabled={disabled}
        error={error}
        className={touched && !!error ? 'phone-with-error' : ''}
      />
      {touched && !!error && (
        <p className="amplify-text amplify-field__error-message">{error}</p>
      )}
    </div>
  );
};
