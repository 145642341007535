import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  formContainer: {
    padding: '0 60px',
    '@media (max-width: 576px)': {
      padding: '0 20px'
    }
  },
  radioButtons: {
    paddingTop: 33
  },
  heightSmallInput: {
    width: 70,
    '@media (max-width: 576px)': {
      width: 60
    }
  },
  heightSmallInputWithoutLabel: {
    paddingTop: 24
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  backButton: {
    width: 200,
    '@media (max-width: 576px)': {
      marginBottom: 20
    }
  },
  termsAndConditions: {
    paddingTop: 10
  },
  submitButton: {
    width: '200px !important'
  },
  ageInput: {
    width: 150,
    '@media (max-width: 576px)': {
      width: '100%'
    }
  },
  input: {
    width: 150,
    marginRight: 25,
    '@media (max-width: 576px)': {
      width: 'calc(120px + 1rem)',
      marginRight: 0
    }
  }
});
