import * as React from 'react';
import { Alert as AWSAlert, AlertProps } from '@aws-amplify/ui-react';
import { useStyles } from './styles';

export const Alert: React.FC<AlertProps & { children: React.ReactElement }> = ({
  children,
  ...props
}) => {
  const styles = useStyles();

  return (
    <div className={styles.alertWrapper}>
      <AWSAlert isDismissible {...props}>
        {children}
      </AWSAlert>
    </div>
  );
};
