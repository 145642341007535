import { Button } from '@/components';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import * as React from 'react';
import { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useStyles } from './styles';
import classNames from 'classnames';

const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

export const Menu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const styles = useStyles();
  const {
    asset_list: { faq_url, support_url }
  } = useContext(AssetsContext);

  return (
    <div
      className={classNames(styles.menu, { [styles.openMenu]: isMenuVisible })}
    >
      <div
        className={classNames(styles.burger, {
          [styles.openBurger]: isMenuVisible
        })}
        onClick={() => setIsMenuVisible(!isMenuVisible)}
      >
        <span />
      </div>
      {isMenuVisible && (
        <ul>
          <li>
            <Button
              onClick={() => (location.href = faq_url)}
              className={styles.button}
              text="FAQ"
              size="large"
            />
          </li>
          <li>
            <Button
              onClick={() => (location.href = support_url)}
              className={styles.button}
              text="Support"
              size="large"
            />
          </li>
          <li>
            <Button
              onClick={signOut}
              className={styles.button}
              text="Log out"
              size="large"
            />
          </li>
        </ul>
      )}
    </div>
  );
};
