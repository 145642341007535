import { getUser, getUserScans } from '@/api';
import { ContentBlock, Typography, Button, Loader } from '@/components';
import { formatDateWithTime } from '@/utils';
import { Grid, View } from '@aws-amplify/ui-react';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from './styles';

const getScanStatus = (status: string) => {
  switch (status) {
    case 'complete':
      return 'Complete';
    case 'failed':
      return 'Failed';
    case 'waiting for data':
      return 'Waiting for Data';
    default:
      return 'Processing';
  }
};

export const Scans = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const params = useParams();
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [scans, setScans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const customerData = await getUser(params.customerId);
      if (!customerData.status) {
        const scansData = await getUserScans(params.customerId);
        setScans(scansData);
        setCurrentCustomer(customerData);
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!currentCustomer) return null;

  return (
    <ContentBlock>
      <div className={styles.header}>
        <Typography component="h2" className={styles.title}>
          Scan History
        </Typography>
        <Typography component="p">
          <span>Customer:</span> {currentCustomer.firstName}{' '}
          {currentCustomer.lastName}
        </Typography>
      </div>
      {scans.length > 0 ? (
        <Grid
          columnGap="1rem"
          rowGap="1rem"
          templateColumns="1fr 1fr"
          className={styles.scans}
        >
          {scans.map((scan) => (
            <View key={scan.id} columnSpan={[2, 1]} className={styles.scan}>
              <Typography component="p" className={styles.scanInfo}>
                <span>Date:</span> {formatDateWithTime(scan.date) || '-'}
              </Typography>
              <Typography component="p" className={styles.scanInfo}>
                <span>Status:</span> {getScanStatus(scan.status) || '-'}
              </Typography>
              {scan.status === 'complete' && (
                <Button
                  text="Scan Measurements"
                  buttonType="dark"
                  className={styles.scanButton}
                  onClick={() =>
                    navigate(`/scans/${scan.id}/${params.customerId}`)
                  }
                />
              )}
            </View>
          ))}
        </Grid>
      ) : null}
      <Button
        buttonType="secondary"
        text="Back to Profile"
        className={styles.backButton}
        onClick={() => navigate(`/customers/${params.customerId}`)}
      />
    </ContentBlock>
  );
};
