import { ContentBlock, Typography } from '@/components';
import * as React from 'react';
import { useContext } from 'react';
import { useStyles } from './styles';
import { SearchForm, SearchCustomerData } from './SearchForm';
import { useNavigate } from 'react-router-dom';
import { getCustomerProvision } from '@/api';
import { AlertContext, AppContext } from '@/GlobalProvider/GlobalProvider';

export const SearchCustomer = () => {
  const styles = useStyles();
  const { id } = useContext(AppContext);
  const { setAlert } = useContext(AlertContext);
  const navigate = useNavigate();

  const searchHandler = async ({ email, phone }: SearchCustomerData) => {
    try {
      if (email) {
        const customer = await getCustomerProvision(id, email);
        if (customer.id) {
          navigate(`/customers/${customer.id}`);
          return;
        }
      }

      if (phone) {
        const customer = await getCustomerProvision(id, phone);
        if (customer.id) {
          navigate(`/customers/${customer.id}`);
          return;
        }
      }

      setAlert({
        text: 'User with this data was not found!',
        variation: 'error'
      });
    } catch (e) {
      const error = JSON.stringify(e.message);

      if (error === '"Network Error"') {
        setAlert({
          text: 'Check your internet and try again.',
          variation: 'error'
        });
      } else {
        setAlert({
          text: 'Something went wrong',
          variation: 'error'
        });
      }
    }
  };

  return (
    <ContentBlock hasBackButton>
      <div className={styles.header}>
        <Typography className={styles.title} component="h2">
          Customer Search
        </Typography>
        <Typography component="h6">
          Search by phone number or email address
        </Typography>
      </div>
      <SearchForm onSubmit={searchHandler} />
    </ContentBlock>
  );
};
