export const theme = {
  colors: {
    white: '#fff',
    black: '#000',
    grey: '#606060',
    grey2: '#f0eded',
    lightGrey: '#b5b5b5',
    error: '#ff3030',
    lightError: '#ffe9e9',
    green: 'linear-gradient(180deg, #62BF97 0%, #239564 100%)'
  }
};
