import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(({ colors }) => ({
  loaderWrapper: () => ({
    background: colors.white,
    width: 200,
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    '& svg': {
      width: 50,
      height: 50
    }
  })
}));
