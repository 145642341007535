import {
  deleteScan,
  getAppDateQueries,
  getCoreMeasurements,
  getDerivedData,
  getScan,
  getScanImage,
  getUser
} from '@/api';
import { ContentBlock, Typography, Button, Loader } from '@/components';
import {
  AlertContext,
  AppContext,
  AssetsContext
} from '@/GlobalProvider/GlobalProvider';
import { formatDateWithTime } from '@/utils';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  View
} from '@aws-amplify/ui-react';
import * as React from 'react';
import { useState, useEffect, useMemo, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from './styles';

const TrashIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 315 315"
    enableBackground="new 0 0 315 315"
  >
    <g>
      <path d="m256.774,23.942h-64.836v-6.465c0-9.636-7.744-17.477-17.263-17.477h-34.348c-9.521,0-17.266,7.841-17.266,17.478v6.465h-64.835c-9.619,0-17.445,7.76-17.445,17.297v11.429c0,7.168 4.42,13.33 10.698,15.951 1.989,39.623 13.5,231.193 14.018,239.801 0.222,3.696 3.284,6.58 6.987,6.58h170.033c3.703,0 6.766-2.884 6.987-6.58 0.518-8.607 12.028-200.178 14.018-239.801 6.278-2.621 10.698-8.783 10.698-15.951v-11.43c5.68434e-14-9.537-7.826-17.297-17.446-17.297zm-119.713-6.464c0-1.918 1.465-3.478 3.266-3.478h34.348c1.8,0 3.264,1.56 3.264,3.478v6.465h-40.877v-6.465zm-82.282,23.761c0-1.818 1.546-3.297 3.445-3.297h198.549c1.899,0 3.445,1.478 3.445,3.297v11.429c0,1.819-1.546,3.299-3.445,3.299h-198.548c-1.899,0-3.445-1.479-3.445-3.299v-11.429zm181.143,259.761h-156.848c-2.055-34.247-11.479-191.674-13.51-231.033h183.867c-2.031,39.359-11.454,196.786-13.509,231.033z" />
      <path d="m157.5,95.125c-3.866,0-7,3.134-7,7v176.109c0,3.866 3.134,7 7,7 3.866,0 7-3.134 7-7v-176.109c0-3.866-3.134-7-7-7z" />
      <path d="m110.2,102.04c-0.202-3.86-3.507-6.837-7.355-6.625-3.86,0.201-6.827,3.494-6.625,7.355l9.182,175.829c0.195,3.736 3.285,6.635 6.984,6.635 0.123,0 0.247-0.003 0.371-0.01 3.86-0.201 6.827-3.494 6.625-7.355l-9.182-175.829z" />
      <path d="m212.155,95.415c-3.899-0.223-7.153,2.764-7.355,6.625l-9.184,175.829c-0.202,3.861 2.765,7.154 6.625,7.355 0.125,0.007 0.248,0.01 0.371,0.01 3.698,0 6.789-2.898 6.984-6.635l9.184-175.829c0.202-3.861-2.764-7.154-6.625-7.355z" />
    </g>
  </svg>
);

export const Scan = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = useContext(AppContext);
  const styles = useStyles();
  const { setAlert } = useContext(AlertContext);
  const {
    behavior_list: { scan_review_show_avatar }
  } = useContext(AssetsContext);

  const [currentScan, setCurrentScan] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [measurements, setMeasurements] = useState([]);
  const [hasCustomMeasures, setHasCustomMeasures] = useState(false);
  const [scanImage, setScanImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const deleteScanHandel = async () => {
    try {
      await deleteScan(params.scanId);
      navigate(`/customers/scans/${params.customerId}`);
    } catch (e) {
      const error = JSON.stringify(e.message);

      if (error === '"Network Error"') {
        setAlert({
          text: 'Check your internet and try again.',
          variation: 'error'
        });
      } else {
        setAlert({
          text: 'User with this data was not found!',
          variation: 'error'
        });
      }
    }
  };

  const measurementsArray = useMemo(() => {
    const keisArray = Object.keys(measurements);
    if (hasCustomMeasures) {
      return keisArray;
    }

    return keisArray.sort((a, b) => {
      const textA = a.toUpperCase();
      const textB = b.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }, [hasCustomMeasures, measurements]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const [scan, customer, scanImage, addData] = await Promise.all([
        getScan(params.scanId),
        getUser(params.customerId),
        getScanImage(params.scanId),
        getAppDateQueries(id)
      ]);
      const customMeasures = addData.find(
        (elem) => elem.query === 'app-measures'
      );
      setHasCustomMeasures(customMeasures);
      if (customMeasures) {
        const appMeasures = await getDerivedData(scan.id, customMeasures.query);
        setMeasurements(appMeasures.output);
      } else {
        const coreMeasurements = await getCoreMeasurements(params.scanId);
        setMeasurements(coreMeasurements.output.measurements);
      }
      setScanImage(scanImage.output.url);
      setCurrentCustomer(customer);
      setCurrentScan(scan);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!currentScan || !currentCustomer) {
    return null;
  }

  const userInfo = (
    <div>
      <Typography component="p">
        <span>Customer:</span> {currentCustomer.firstName}{' '}
        {currentCustomer.lastName}
      </Typography>
      <Typography component="p">
        <span>Date & time:</span> {formatDateWithTime(currentScan.date)}
      </Typography>
    </div>
  );

  const show_avatar = scan_review_show_avatar === 'true';

  return (
    <ContentBlock>
      <div className={styles.header}>
        <Typography component="h2" className={styles.title}>
          Scan History
        </Typography>
        {show_avatar && userInfo}
      </div>
      <Grid templateColumns="1fr 1fr" columnGap="1.5rem">
        <View columnSpan={[2, 1]}>
          {show_avatar ? (
            <div className={styles.image}>
              <img src={scanImage} />
            </div>
          ) : (
            <div className={styles.userInfo}>{userInfo}</div>
          )}
        </View>
        <View columnSpan={[2, 1]}>
          <div className={styles.tableWrap}>
            <Table caption="" highlightOnHover={false} variation="striped">
              <TableHead>
                <TableRow>
                  <TableCell as="th">Name</TableCell>
                  <TableCell as="th">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {measurementsArray.map((key) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>
                      {Number(measurements[key]).toFixed(1)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </View>
      </Grid>
      <div className={styles.footer}>
        <Button
          buttonType="secondary"
          text="Back to Profile"
          className={styles.backButton}
          onClick={() => navigate(`/customers/${params.customerId}`)}
        />
        <Button
          buttonType="error"
          text={
            <span>
              Remove
              <TrashIcon />
            </span>
          }
          className={styles.removeButton}
          onClick={deleteScanHandel}
        />
      </div>
    </ContentBlock>
  );
};
