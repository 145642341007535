import * as React from 'react';
import { useState, useEffect, useRef, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { EMAIL_REGEX } from '@/regex';
import { Button, TextInput, PhoneInputField } from '@/components';
import { useStyles } from './styles';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';

const getSchema = (phoneLength) =>
  Yup.object().shape(
    {
      email: Yup.string()
        .when('phone', {
          is: (phoneNumber) => !phoneNumber,
          then: Yup.string().required(
            'Phone number or email address is required'
          ),
          otherwise: Yup.string()
        })
        .matches(EMAIL_REGEX, 'Enter valid email address'),
      phone: Yup.string().when('email', {
        is: (email) => !email,
        then: Yup.string()
          .required('Phone number or email address is required')
          .test(
            'len',
            `Must be exactly ${phoneLength} characters`,
            (val) => val?.length === phoneLength
          ),
        otherwise: Yup.string().test(
          'len',
          `Must be exactly ${phoneLength} characters`,
          (val) => {
            if (!val || val === '1') return true;
            return val?.length === phoneLength;
          }
        )
      })
    },
    [['phone', 'email']]
  );

const initialValues = {
  email: '',
  phone: ''
};

export type SearchCustomerData = {
  email: string;
  phone: string;
};

interface SearchCustomerFormProps {
  onSubmit: (params: SearchCustomerData) => void;
}
export const SearchForm: React.FC<SearchCustomerFormProps> = ({ onSubmit }) => {
  const styles = useStyles();
  const [phoneLength, setPhoneLength] = useState(11);
  const emailInputRef = useRef<HTMLInputElement>();
  const {
    behavior_list: { customer_account_handle }
  } = useContext(AssetsContext);

  const showPhoneInput =
    customer_account_handle === 'phone_only' ||
    customer_account_handle === 'email_or_phone';
  const showEmailInput =
    customer_account_handle === 'email_only' ||
    customer_account_handle === 'email_or_phone';

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.inputMode = 'email';
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getSchema(phoneLength)}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        setFieldValue
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={styles.formInputs}>
              {showPhoneInput && (
                <div className={styles.input}>
                  <PhoneInputField
                    value={values.phone}
                    error={errors.phone as string}
                    touched={touched.phone as boolean}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    setPhoneLength={setPhoneLength}
                  />
                </div>
              )}
              {showEmailInput && (
                <div className={styles.input}>
                  <TextInput
                    ref={emailInputRef}
                    label="Email address"
                    type="text"
                    name="email"
                    placeholder="Enter email address"
                    value={values.email.toLowerCase()}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/\s/g, '');
                      handleChange(e);
                    }}
                    hasError={touched.email && !!errors.email}
                    errorMessage={errors.email}
                  />
                </div>
              )}
            </div>
            <div className={styles.buttonBlock}>
              <Button type="submit" text="Search" buttonType="secondary" />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
